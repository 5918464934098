import { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";
import { useApiQuery } from "hooks/useAPI";
import { AccessManagementUserBase } from "types/auth";

/**
 * Custom hook for fetching the current user's information.
 *
 * @param {Omit<UseQueryOptions<AccessManagementUserBase, Error>, "queryKey" | "queryFn"> & { config?: AxiosRequestConfig }} [options]
 * Override default options or add new ones.
 *
 * @returns {UseQueryResult<AccessManagementUserBase, Error>} A query result object for fetching user  information.
 *
 * @example
 * const { data: userInfo, isLoading, error } = useUserMeQuery({
 *   staleTime: 5 * 60 * 1000, // 5 minutes
 *   onSuccess: (data) => {
 *     console.log('Fetched  info:', data);
 *   },
 *   config: {
 *     headers: {
 *       'Custom-Header': 'value'
 *     }
 *   }
 * });
 */
export function useUserMeQuery(
    options?: Omit<UseQueryOptions<AccessManagementUserBase, Error>, "queryKey" | "queryFn"> & {
        config?: AxiosRequestConfig;
    }
): UseQueryResult<AccessManagementUserBase, Error> {
    return useApiQuery<AccessManagementUserBase>(
        "/auth/users/me",
        ["userMe"],
        options
    );
}