import React  from "react";
import classes from "../AccountModal.module.css";
import { mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import { AccessManagementUserBase } from "types/auth";
import InitialsIcon from "components/_Library/InitialsIcon/InitialsIcon";
import cx from "classnames"
import { useUserMeAccessQuery } from "crud/me_access";
import moment from "moment";
import { MOMENT_DATE_FORMAT } from "index";

interface OwnProps {
    user: AccessManagementUserBase | undefined
    onQuickLinkSelection: (tab: string) => void
}

type OverviewTabProps = OwnProps;

const OverviewTab: React.FC<OverviewTabProps> = ({ onQuickLinkSelection, user }) => {

    const { data } = useUserMeAccessQuery();
    const accessExpiryDate = moment().add(data?.access_expiry_days, 'days').format(MOMENT_DATE_FORMAT)
   
    return (
        <>
            <div className={classes.ContentContainer}>
                <div className={classes.DetailsOverview}>
                    <div className={classes.InitialsIcon}>
                        <InitialsIcon 
                            firstName={user?.first_name} 
                            lastName={user?.last_name} 
                            width={7}
                        />
                    </div>
                    <div className={classes.UserDetailsContainer}>
                        <p className={cx(classes.UserDetails, classes.UserName)}>{user?.first_name} {user?.last_name}</p>
                        <p className={classes.UserDetails}>{user?.job_role}</p>
                        <p className={classes.UserDetails}>{user?.company_name}</p>
                    </div>
                    <div className={classes.MemberBadgeContainer}>
                        <p className={classes.MemberBadge}>Member since {moment.unix(user?.created_at!).format(MOMENT_DATE_FORMAT)}</p>
                    </div>
                </div>
            </div>
            <p>Quick Links:</p>
            <div className={classes.ContentContainer}>
                <div className={classes.QuickLink} onClick={() => onQuickLinkSelection("details")}>
                    <p>Update Account Details</p>
                    <Icon path={mdiChevronRight} size={1.5}/>
                </div>
                <div className={classes.QuickLink} onClick={() => onQuickLinkSelection("notifications")}>
                    <p>Email Notification Settings</p>
                    <Icon path={mdiChevronRight} size={1.5}/>
                </div>
                {
                    data?.has_api_access &&
                        <div className={classes.QuickLink} onClick={() => onQuickLinkSelection("settings")}>
                            <p>Developer API Key</p>
                            <Icon path={mdiChevronRight} size={1.5}/>
                        </div>
                }
                
            </div>
            {data?.access_expiry_days && data?.access_expiry_days > 0 ?
                <div className={cx(classes.ContentContainer, classes.SubscriptionContainer)}>
                    <p className={classes.ExpiryTitle}>Subscription</p>
                    <p className={classes.LoContText}>Your GEO subscription expires on {accessExpiryDate}</p>
                </div>
            : null}
        </>
    )

};

export default OverviewTab;