import React, { useState } from "react";
import classes from "./AccountModal.module.css";
import { Modal, Tabs } from "@mantine/core";
import {
    mdiAccountDetails,
    mdiBell,
    mdiCog,
    mdiCookie,
    mdiHomeAccount
} from "@mdi/js";
import Icon from "@mdi/react";
import InitialsIcon from "components/_Library/InitialsIcon/InitialsIcon";
import DetailsTab from "./Tabs/DetailsTab";
import SettingsTab from "./Tabs/SettingsTab";
import PrivacyTab from "./Tabs/PrivacyTab";
import OverviewTab from "./Tabs/OverviewTab";
import NotificationsTab from "./Tabs/NotificationsTab";
import { AccessManagementUserBase } from "types/auth";

interface OwnProps {
    closeModal: () => void;
    user: AccessManagementUserBase | undefined;
}

type AccountModalProps = OwnProps;

const AccountModal: React.FC<AccountModalProps> = ({ closeModal, user }) => {
    const closeModalWithAnalytics = () => {
        closeModal();
    };

    const [selectedTab, setSelectedTab] = useState<string>("overview");

    return (
        <div>
            <Modal
                opened={true}
                onClose={closeModalWithAnalytics}
                size={"110rem"}
                closeButtonProps={{
                    size: "2rem",
                }}
                classNames={{
                    header: classes.ModalHeader,
                    content: classes.Modal,
                }}
            >
                <div
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                    <Tabs
                        orientation={"vertical"}
                        value={selectedTab}
                        className={classes.TabMenu}
                        variant={"userPreferences"}
                    >
                        <Tabs.List w="16rem">
                            <div className={classes.SettingsTitle}>
                                <InitialsIcon
                                    width={4}
                                    firstName={user?.first_name}
                                    lastName={user?.last_name}
                                />
                                <p className={classes.Title}>Settings</p>
                            </div>
                            <Tabs.Tab
                                w="100%"
                                value={"overview"}
                                icon={<Icon path={mdiHomeAccount} size={1.6} />}
                                onClick={() => setSelectedTab("overview")}
                            >
                                Overview
                            </Tabs.Tab>
                            <Tabs.Tab
                                w="100%"
                                value={"details"}
                                icon={
                                    <Icon path={mdiAccountDetails} size={1.6} />
                                }
                                onClick={() => setSelectedTab("details")}
                            >
                                Details
                            </Tabs.Tab>
                            <Tabs.Tab
                                w="100%"
                                value={"settings"}
                                icon={<Icon path={mdiCog} size={1.6} />}
                                onClick={() => setSelectedTab("settings")}
                            >
                                Settings
                            </Tabs.Tab>
                            <Tabs.Tab
                                w="100%"
                                value={"cookies"}
                                icon={<Icon path={mdiCookie} size={1.6} />}
                                onClick={() => setSelectedTab("cookies")}
                            >
                                Cookies
                            </Tabs.Tab>
                            <Tabs.Tab
                                w="100%"
                                value={"notifications"}
                                icon={<Icon path={mdiBell} size={1.6} />}
                                onClick={() => setSelectedTab("notifications")}
                            >
                                Notifications
                            </Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value={"overview"} pl={"lg"}>
                            <OverviewTab
                                user={user}
                                onQuickLinkSelection={(tab: string) => {
                                    setSelectedTab(tab);
                                }}
                            />
                        </Tabs.Panel>

                        <Tabs.Panel value={"details"} pl={"lg"}>
                            <DetailsTab user={user} />
                        </Tabs.Panel>

                        <Tabs.Panel value={"settings"} pl={"lg"}>
                            <SettingsTab user={user} />
                        </Tabs.Panel>

                        <Tabs.Panel value={"cookies"} pl={"lg"}>
                            <PrivacyTab user={user} />
                        </Tabs.Panel>

                        <Tabs.Panel value={"notifications"} pl={"lg"}>
                            <NotificationsTab user={user!} />
                        </Tabs.Panel>
                    </Tabs>
                </div>
            </Modal>
        </div>
    );
};

export default AccountModal;
