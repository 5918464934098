import { useMemo } from "react";
import { useIntercom } from "react-use-intercom";
import { ExtendedAccessManagementUser } from "types/auth";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

type Props = {
    user: ExtendedAccessManagementUser | null;
};

function useUserPlugins({ user }: Props) {
    const { boot } = useIntercom();
    useMemo(() => {        
        if (import.meta.env.VITE_ENVIRONMENT === "production") {
            const sessionReplayTracking = sessionReplayPlugin(
                {
                    sampleRate: 0.2,
                    // sampleRate: 1,
                    // If debugging, use the following setup
                    // debugMode: true,
                },
            );
            amplitude.add(sessionReplayTracking);
            amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
                userId: user?.id,
            });
        }
    }, [user]);
    
    useMemo(() => {
        if (!user) return;
        boot({
            userId: user.id,
            name: user.name,
            email: user.email,
            createdAt: user.createdAt,
            userHash: user.userHash,
            hideDefaultLauncher: true,
            company: {
                companyId: user.company_id,
                name: user.company_name,
            },
        });
    }, [boot, user]);
}

export default useUserPlugins;
