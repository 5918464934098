import React from "react";
import classes from "../AccountModal.module.css";
import { AccessManagementUserBase } from "types/auth";
import cx from "classnames"
import Button from "components/_Library/Button/Button";
import { useSetState } from "@mantine/hooks";
import { useUpdateUserDetailsMutation } from "crud/hooks/users";
import { useUserMeQuery } from "crud/hooks/me";
import { TextInput } from "@mantine/core";

interface OwnProps {
    user: AccessManagementUserBase | undefined
}

type DetailsTabProps = OwnProps;

const DetailsTab: React.FC<DetailsTabProps> = ({ user }) => {

    const { refetch } = useUserMeQuery();
    
    const [userDetails, setUserDetails] = useSetState({
        first_name: user?.first_name!,
        last_name: user?.last_name!,
        job_role: user?.job_role,
    })

    const createUpdateUserMutation = useUpdateUserDetailsMutation({
        onSuccess: () => refetch(),
    });

    const handleUserUpdate = async () => {
        await createUpdateUserMutation.mutateAsync({
                ...userDetails,
                last_login_at: new Date().toISOString(),
            }); 
    };

    return (
        <div className={classes.ContentContainer}>
            <div className={cx(classes.Input, classes.NameInputs)}>
                <TextInput
                    label={"First Name"}
                    value={userDetails.first_name}
                    variant={"twoColumn"}
                    onChange={(event) => {
                        setUserDetails({
                            first_name: event.currentTarget.value,
                        })
                    }}
                />
                <TextInput
                    label={"Last Name"}
                    value={userDetails.last_name}
                    variant={"twoColumn"}
                    onChange={(event) => {
                        setUserDetails({
                            last_name: event.currentTarget.value,
                        })
                    }}
                />
            </div>
            <div className={classes.Input}>
                <TextInput
                    label={"Job Title"}
                    value={userDetails.job_role}
                    variant={"primaryBg"}
                    onChange={(event) => {
                        setUserDetails({
                            job_role: event.currentTarget.value,
                        })
                    }}
                />
            </div>
            <div className={classes.ButtonContainer}>
                <Button onClick={handleUserUpdate} size={{width: "20rem"}}>Save Changes</Button>
            </div>
        </div>
    )
};

export default DetailsTab;