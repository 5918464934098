import { clamp } from "./Maths";

export function randomHex() {
    return "#000000".replace(/0/g, function () {
        return Math.floor(Math.random() * 16).toString(16);
    });
}

export interface Color {
    r: number;
    g: number;
    b: number;
    a: number;
}

export function parseColorFromString(colorString: string) {
    let color: Color;
    if (colorString[0] === "#") {
        color = parseColorFromHex(colorString);
    } else if (colorString.startsWith("rgba")) {
        color = parseColorFromRGBA(colorString);
    } else if (colorString.startsWith("rgb")) {
        color = parseColorFromRGB(colorString);
    } else {
        color = { r: 255, g: 0, b: 255, a: 255 };
    }
    return color;
}

export function parseColorFromRGBA(rgbaString: string) {
    let arrayString = rgbaString.slice("rgba(".length, rgbaString.indexOf(")"));
    let colorArray = arrayString.split(",").map((item) => item.trim());
    return {
        r: parseInt(colorArray[0]),
        g: parseInt(colorArray[1]),
        b: parseInt(colorArray[2]),
        a: parseInt(colorArray[3]) * 255,
    };
}

export function parseColorFromRGB(rgbaString: string) {
    let arrayString = rgbaString.slice("rgb(".length, rgbaString.indexOf(")"));
    let colorArray = arrayString.split(",").map((item) => item.trim());
    return {
        r: parseInt(colorArray[0]),
        g: parseInt(colorArray[1]),
        b: parseInt(colorArray[2]),
        a: 255,
    };
}

export function parseColorFromHex(hexString: string) {
    let color: Color;
    switch (hexString.length) {
        case 4:
            color = {
                r: parseInt(hexString[2], 16),
                g: parseInt(hexString[3], 16),
                b: parseInt(hexString[4], 16),
                a: 255,
            };
            break;
        case 7:
            color = {
                r: parseInt(hexString.slice(1, 3), 16),
                g: parseInt(hexString.slice(3, 5), 16),
                b: parseInt(hexString.slice(5), 16),
                a: 255,
            };
            break;
        case 9:
            color = {
                r: parseInt(hexString.slice(1, 3), 16),
                g: parseInt(hexString.slice(3, 5), 16),
                b: parseInt(hexString.slice(5, 7), 16),
                a: parseInt(hexString.slice(7), 16),
            };
            break;
        default:
            color = { r: 255, g: 0, b: 255, a: 255 };
    }
    return color;
}

export function isValidColor(color: string) {
    if (color.charAt(0) === "#") {
        // hex match
        color = color.substring(1);
        return (
            [3, 4, 6, 8].indexOf(color.length) > -1 &&
            !isNaN(parseInt(color, 16))
        );
    } else if (
        CSS_COLOR_NAMES.includes(color) || // direct name matches
        CSS_COLOR_NAMES.includes(
            color.charAt(0).toUpperCase() + color.slice(1),
        ) || // lowercase matches
        CSS_COLOR_NAMES.includes(
            color
                .split(" ")
                .map((word) => {
                    // lowercase with spaces matches
                    return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join(""),
        )
    ) {
        return true;
    } else {
        // eslint-disable-next-line -- complains about unnecessary escape chars "\/" & "\.". Not willing to remove.
        return /^(rgb|hsl)a?\((\d+%?(deg|rad|grad|turn)?[,\s]+){2,3}[\s\/]*[\d\.]+%?\)$/i.test(
            color,
        ); //rgb(a) hsl(a)
    }
}

export function HexFromColor(color: Color) {
    return `#${Object.values(color)
        .map((channelValue) =>
            parseInt(channelValue.toFixed(0)).toString(16).padStart(2, "0"),
        )
        .join("")}`;
}

export function ColorDarken(hexString: string, darkeningFactor: number) {
    let color: Color = parseColorFromHex(hexString);
    color.r = clamp(0, 255, color.r / darkeningFactor);
    color.g = clamp(0, 255, color.g / darkeningFactor);
    color.b = clamp(0, 255, color.b / darkeningFactor);
    return HexFromColor(color);
}

export function getTextColor(color: string): "black" | "white" {
    // Helper to convert hex to RGB
    const hexToRgb = (hex: string): number[] => {
        const cleaned = hex.replace("#", "");
        if (cleaned.length === 3) {
            return [
                parseInt(cleaned[0] + cleaned[0], 16),
                parseInt(cleaned[1] + cleaned[1], 16),
                parseInt(cleaned[2] + cleaned[2], 16),
            ];
        }
        return [
            parseInt(cleaned.substring(0, 2), 16),
            parseInt(cleaned.substring(2, 4), 16),
            parseInt(cleaned.substring(4, 6), 16),
        ];
    };

    // Helper to parse RGB/RGBA strings
    const parseRgb = (rgb: string): number[] => {
        return rgb
            .replace(/[rgba()]/g, "")
            .split(",")
            .map((x) => parseInt(x.trim()));
    };

    // Extract RGB values based on color format
    let rgb: number[];

    if (color.startsWith("#")) {
        rgb = hexToRgb(color);
    } else if (color.startsWith("rgb")) {
        rgb = parseRgb(color);
    } else {
        throw new Error("Invalid color format. Must be hex, rgb(), or rgba()");
    }

    // Calculate perceived brightness using YIQ formula
    const brightness = Math.round(
        (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000,
    );

    return brightness > 125 ? "black" : "white";
}

const CSS_COLOR_NAMES = [
    "AliceBlue",
    "AntiqueWhite",
    "Aqua",
    "Aquamarine",
    "Azure",
    "Beige",
    "Bisque",
    "Black",
    "BlanchedAlmond",
    "Blue",
    "BlueViolet",
    "Brown",
    "BurlyWood",
    "CadetBlue",
    "Chartreuse",
    "Chocolate",
    "Coral",
    "CornflowerBlue",
    "Cornsilk",
    "Crimson",
    "Cyan",
    "DarkBlue",
    "DarkCyan",
    "DarkGoldenRod",
    "DarkGray",
    "DarkGrey",
    "DarkGreen",
    "DarkKhaki",
    "DarkMagenta",
    "DarkOliveGreen",
    "DarkOrange",
    "DarkOrchid",
    "DarkRed",
    "DarkSalmon",
    "DarkSeaGreen",
    "DarkSlateBlue",
    "DarkSlateGray",
    "DarkSlateGrey",
    "DarkTurquoise",
    "DarkViolet",
    "DeepPink",
    "DeepSkyBlue",
    "DimGray",
    "DimGrey",
    "DodgerBlue",
    "FireBrick",
    "FloralWhite",
    "ForestGreen",
    "Fuchsia",
    "Gainsboro",
    "GhostWhite",
    "Gold",
    "GoldenRod",
    "Gray",
    "Grey",
    "Green",
    "GreenYellow",
    "HoneyDew",
    "HotPink",
    "IndianRed",
    "Indigo",
    "Ivory",
    "Khaki",
    "Lavender",
    "LavenderBlush",
    "LawnGreen",
    "LemonChiffon",
    "LightBlue",
    "LightCoral",
    "LightCyan",
    "LightGoldenRodYellow",
    "LightGray",
    "LightGrey",
    "LightGreen",
    "LightPink",
    "LightSalmon",
    "LightSeaGreen",
    "LightSkyBlue",
    "LightSlateGray",
    "LightSlateGrey",
    "LightSteelBlue",
    "LightYellow",
    "Lime",
    "LimeGreen",
    "Linen",
    "Magenta",
    "Maroon",
    "MediumAquaMarine",
    "MediumBlue",
    "MediumOrchid",
    "MediumPurple",
    "MediumSeaGreen",
    "MediumSlateBlue",
    "MediumSpringGreen",
    "MediumTurquoise",
    "MediumVioletRed",
    "MidnightBlue",
    "MintCream",
    "MistyRose",
    "Moccasin",
    "NavajoWhite",
    "Navy",
    "OldLace",
    "Olive",
    "OliveDrab",
    "Orange",
    "OrangeRed",
    "Orchid",
    "PaleGoldenRod",
    "PaleGreen",
    "PaleTurquoise",
    "PaleVioletRed",
    "PapayaWhip",
    "PeachPuff",
    "Peru",
    "Pink",
    "Plum",
    "PowderBlue",
    "Purple",
    "RebeccaPurple",
    "Red",
    "RosyBrown",
    "RoyalBlue",
    "SaddleBrown",
    "Salmon",
    "SandyBrown",
    "SeaGreen",
    "SeaShell",
    "Sienna",
    "Silver",
    "SkyBlue",
    "SlateBlue",
    "SlateGray",
    "SlateGrey",
    "Snow",
    "SpringGreen",
    "SteelBlue",
    "Tan",
    "Teal",
    "Thistle",
    "Tomato",
    "Turquoise",
    "Violet",
    "Wheat",
    "White",
    "WhiteSmoke",
    "Yellow",
    "YellowGreen",
];

export const DamageAssessments = [
    "No Damage",
    "Light Damage",
    "Moderate Damage",
    "Substantial Damage",
    "Severe Damage",
    "Destroyed",
    "Damage Cannot be Ascertained",
    "Not Assessed",
    "Low Risk of Damage",
    "Risk of Internal Damage",
] as const;

export const FloodDamageAssessments = [
    "None",
    "Light",
    "Moderate",
    "Severe",
    "Other",
] as const;

export const DepricatedDamageAssessments = ["Cannot be Ascertained"] as const;

export const FloodExposureAssessment = "Potentially Flooded";

export type DamageAssessment =
    | (typeof DamageAssessments)[number]
    | (typeof FloodDamageAssessments)[number]
    | typeof FloodExposureAssessment
    | (typeof DepricatedDamageAssessments)[number];

export const DamageAssessmentColors: { [key in DamageAssessment]: string } = {
    "No Damage": "#2196f3",
    "Light Damage": "#00af25",
    "Moderate Damage": "#afdc00",
    "Substantial Damage": "#fdc400",
    "Severe Damage": "#fb7a00",
    Destroyed: "#f40b00",
    "Damage Cannot be Ascertained": "#888888",
    "Cannot be Ascertained": "#888888",
    "Not Assessed": "#cccccc",
    "Low Risk of Damage": "#667383", //If this colour is changed, update Insights Pie legend colours appropriately. Search for DMG_CAT_COLOURS in project to find.
    "Risk of Internal Damage": "#837C61", //If this colour is changed, update Insights Pie legend colours appropriately. Search for DMG_CAT_COLOURS in project to find.
    // Flood Colors //
    None: "#ffffff",
    Light: "#93b1ce",
    Moderate: "#3d7dc8",
    Severe: "#1950a5",
    Other: "#888888",
    "Potentially Flooded": "#50E3E3",
};

export const DamageAssessmentBorderColors: {
    [key in DamageAssessment]?: string;
} = {
    "Low Risk of Damage": "#2196f3",
    "Risk of Internal Damage": "#fdc400",
};
