import React from "react";
import {
    NEW_THEME_COOKIE,
    OLD_THEME_COOKIE,
    deleteCookie,
    getCookie,
    setCookie,
} from "../../../utils/Cookies";
import cx from "classnames";
import classes from "./ThemeToggle.module.css";
import ReactTooltip from "react-tooltip";
import { bindActionCreators, Dispatch } from "redux";
import { MapActionTypes } from "../../../store/report/reportTypes";
import { connect } from "react-redux";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { RootState } from "store/store";
import { setTheme } from "store/user/userActions";
import withAnalytics, { withAnalyticsProps } from "../HOC/withAnalytics";
import { createUpdatePreferences } from "crud/accessCRUD";
import { withOktaAuth } from "@okta/okta-react";
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";
import { UserState } from "store/user/userTypes";
import { ThemeOption } from "store/system/systemTypes";

interface DispatchProps {
    setTheme: typeof setTheme;
}

interface StateProps {
    theme: ThemeOption;
    user: UserState["user"];
}

type ThemeToggleProps = DispatchProps &
    StateProps &
    withAnalyticsProps &
    IOktaContext;

class ThemeToggle extends React.Component<ThemeToggleProps> {
    private currentTheme =
        this.props.theme ||
        getCookie(NEW_THEME_COOKIE) ||
        getCookie(OLD_THEME_COOKIE) ||
        "light";

    triggerUpdate = (theme: ThemeOption) => {
        document.body.setAttribute("data-theme", theme);
        this.props.setTheme(theme as ThemeOption);
        deleteCookie(OLD_THEME_COOKIE);
        setCookie(NEW_THEME_COOKIE, theme, 365);

        let htmlElem = document.getElementsByTagName("html")[0];
        htmlElem.dataset["theme"] = theme;
        this.currentTheme = theme;

        this.props.analytics.trackUserEvent({
            name: "theme_changed",
            payload: { theme: theme },
        });

        this.updateUserModel(theme);
    };

    updateUserModel = async (theme: ThemeOption) => {
        let newToken = await this.props.oktaAuth.getAccessToken();
        await createUpdatePreferences(newToken!, {
            tos: this.props.user!.tos,
            marketing_emails: this.props.user!.marketing_emails,
            event_notifications: this.props.user!.event_notifications,
            theme,
        });
    };

    render() {
        return (
            <div className={classes.ToggleContainer}>
                Dark Mode
                <div
                    className={cx(classes.ToggleTheme, {
                        [classes.DarkModeActive]: this.props.theme === "dark",
                    })}
                    onClick={() => {
                        let nextTheme: ThemeOption =
                            this.currentTheme === "light" ? "dark" : "light";
                        this.triggerUpdate(nextTheme);
                    }}
                    data-for={"TextTooltip"}
                    data-tip={`Toggle Theme`}
                >
                    <div className={classes.InnerToggle}></div>
                    <ReactTooltip
                        id={"TextTooltip"}
                        place={"left"}
                        effect={"float"}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    theme: getStoreAtNamespaceKey(state, "user").theme,
    user: getStoreAtNamespaceKey(state, "user").user,
});

const mapDispatchToProps = (dispatch: Dispatch<MapActionTypes>) => ({
    setTheme: bindActionCreators(setTheme, dispatch),
});

export default withOktaAuth(
    connect(mapStateToProps, mapDispatchToProps)(withAnalytics(ThemeToggle)),
);
