import { PortfolioItem, CompanyEditCreateSchema } from "store/user/userTypes";
import {
    makeAPICall_DynamicUrl,
    makeAPICall_StaticUrl,
} from "../utils/APIHelpers";
import { genericAPIResponse, unsuccessfulAPIResponse } from "./genericResponse";
import { AccessManagementUser } from "types/auth";

export interface GroupSchema {
    id: string;
    name: string;
    has_beta_layers?: boolean;
}

export interface UserWithGroupDataSchema {
    id: string;
    group_id: string;
    group_name: string;
}

export interface NewGroupSchema {
    group: {
        id: string;
        name: string;
        has_beta_layers?: boolean;
        assign_default_layers?: boolean;
    };
    users: {
        id: string;
        first_name: string;
        last_name: string;
    }[];
    portfolios: {
        name: string;
        id: string;
    }[];
    access: {
        event_access: EventAccessSchema[];
    };
}

export interface UserSchema {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    job_role: string;
    is_admin: boolean;
    company_id: string;
    company_name?: string;
    last_login_at?: string;
}

export interface BaseEventAccessSchema {
    id?: string;
    event_types: string[];
    impact: string[];
    lloyds_cat_code: boolean;
    specific_event_names: string[];
    countries: string[];
    group_id?: string;
}

export interface InsightsAccessSchema {
    id?: string;
    group_id: string;
    portfolio_id: string;
    data_hosting: boolean;
    download_insights: boolean;
}

export interface PolicyAccessSchema {
    id?: string;
    portfolio_id: string;
    enabled: boolean;
}

export interface EventAccessSchema extends BaseEventAccessSchema {
    start_date: string;
    end_date: string;
}
export interface CreateUpdateEventAccessSchema {
    event_types: string[];
    impact: string[];
    start_date: string;
    end_date: string | null;
    lloyds_cat_code: boolean;
    specific_event_ids: string[];
    countries: string[];
    group_id?: string;
    id?: string;
}

export interface LayerAccessSchema {
    description: string;
    id: string;
    name: string;
}

export interface GroupTierSchema {
    id?: string;
    group_id: string;
    tier_id: string;
    effective_from?: string;
    effective_to: string | null;
}

export interface NewUserSchema {
    user: UserSchema;
    user_groups: {
        id: string;
        group_id: string;
        group_name: string;
    }[];
    groups_portfolios: {
        group: {
            id: string;
            name: string;
        };
        portfolios: {
            id: string;
            name: string;
        }[];
    }[];
    access: {
        event_access: EventAccessSchema[];
    };
}

export interface PaginatedNewUserSchema {
    page: number;
    total: number;
    limit: number;
    success: boolean;
    message: string;
    data: NewUserSchema[];
}

export interface PaginatedNewGroupSchema {
    page: number;
    total: number;
    limit: number;
    success: boolean;
    message: string;
    data: NewGroupSchema[];
}

export interface UserGroupSchema {
    id: string;
    user_id: string;
    group_id: string;
}

export interface GroupAddUserSchema {
    group_id: string;
    user_id: string;
}

export interface FeedbackDetailsSchema {
    rating: number;
    details: string;
    type: string;
}
export interface FeedbackSchema extends FeedbackDetailsSchema {
    user_id: string;
}

export interface FeedbackSchemaResponse {
    success: boolean;
    message: string;
    data: FeedbackSchema;
}

export type PortfolioCreateUpdateSchema = {
    id?: string;
    name: string;
};

export type ExtendedPortfolioSchema = {
    id?: string;
    name: string;
    policy_access_id?: string;
    policy_access_enabled?: boolean;
}

export type PortfolioSchema = {
    id: string;
    name: string;
};

export type UserPreferences = {
    tos: boolean;
    theme?: string;
    event_notifications: boolean,
    marketing_emails: boolean
};

export interface UserMeEdit {
    first_name: string;
    last_name: string;
    last_login_at: string | null;
    job_role?: string;
}

export interface GroupPortfolioSchema {
    id: string;
    group_id: string;
    portfolio_id: string;
}

export const groupGet = makeAPICall_StaticUrl<
    genericAPIResponse<GroupSchema[]>
>("/auth/groups/", "GET");

export const groupsGetAllAccess = makeAPICall_DynamicUrl<
    PaginatedNewGroupSchema | unsuccessfulAPIResponse,
    { search: string; page: number; limit: number },
    {}
>("/auth/groups/access/", "GET");

export const userGet = makeAPICall_StaticUrl<genericAPIResponse<UserSchema[]>>(
    "/auth/users/",
    "GET",
);

export const usersGetAllAccess = makeAPICall_DynamicUrl<
    PaginatedNewUserSchema | unsuccessfulAPIResponse,
    { search: string; page: number; limit: number; order?: string },
    {}
>("/auth/users/access/", "GET");

export const userMeUpdate = makeAPICall_StaticUrl<
    genericAPIResponse<{}>,
    UserMeEdit
>("/auth/users/me", "PATCH");

export const groupAddUser = makeAPICall_StaticUrl<
    GroupAddUserSchema,
    GroupAddUserSchema
>("/auth/users_groups/", "POST");

export const companyCreateOrUpdate = makeAPICall_StaticUrl<
    genericAPIResponse<CompanyEditCreateSchema>,
    Omit<CompanyEditCreateSchema, "id">
>("/auth/companies/", "PUT");

export const feedbackCreate = makeAPICall_StaticUrl<
    FeedbackSchemaResponse,
    FeedbackDetailsSchema
>("/feedback/", "POST");

export const portfolioGet = makeAPICall_StaticUrl<
    genericAPIResponse<PortfolioItem[]>
>("/portfolios/", "GET");

export const portfolioAllGet = makeAPICall_StaticUrl<
    genericAPIResponse<PortfolioItem[]>
>("/auth/portfolios/", "GET");

export const getUserMe = makeAPICall_StaticUrl<
    genericAPIResponse<AccessManagementUser>
>("/auth/users/me", "GET");

export const createUpdatePreferences = makeAPICall_StaticUrl<
    genericAPIResponse<String>,
    Partial<UserPreferences>
>("/auth/users/me/preferences", "PATCH");
