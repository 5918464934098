import { UserData } from "components/Pages/AdminPage/AccessManagement/UserManagement/UserFlow";
import { useApiMutationWithAlert, UseApiMutationWithAlertParams, useApiQueryWithAlert, UseApiQueryWithAlertParams } from "crud/hooks/useAPI";
import { UserMeEdit, UserSchema } from "crud/accessCRUD";
import { UserNotifications } from "types/auth";

/**
 * Predefined hook for creating a user.
 *
 * @param {Partial<UseApiMutationWithAlertParams<UserSchema, Error, UserData>['options']>} [customOptions]
 * Override default options or add new ones.
 *
 * @returns {UseMutationResult<UserSchema, Error, UserData>} A mutation result object for creating a user.
 *
 * @example
 * const createUser = useCreateUpdateUserMutation({
 *   onSuccess: (data) => {
 *     console.log('User created successfully:', data);
 *     closeModal();
 *   }
 * });
 *
 * Usage:
 * createUser.mutate(newUserData);
 */
export function useCreateUpdateUserMutation(
    customOptions?: Partial<
        UseApiMutationWithAlertParams<UserSchema, Error, UserData>["options"]
    >,
) {
    return useApiMutationWithAlert<UserSchema, Error, UserData>({
        url: "/auth/users/",
        method: "PUT",
        options: {
            successMessage: "User created successfully",
            ...customOptions,
        },
    });
}

export function useUpdateUserDetailsMutation(
    customOptions?: Partial<
        UseApiMutationWithAlertParams<UserMeEdit, Error, UserMeEdit>["options"]
    >,
) {
    return useApiMutationWithAlert<UserMeEdit, Error, UserMeEdit>({
        url: "/auth/users/me",
        method: "PATCH",
        options: {
            successMessage: "Details updated successfully",
            ...customOptions,
        },
    });
}

export function useUpdateUserNotificationsMutation(
    customOptions?: Partial<
        UseApiMutationWithAlertParams<UserNotifications, Error, UserNotifications>["options"]
    >,
) {
    return useApiMutationWithAlert<UserNotifications, Error, UserNotifications>({
        url: "/users/me/notifications/",
        method: "PUT",
        options: {
            successMessage: "Notification settings updated successfully",
            ...customOptions,
        },
    });
}


/**
 * Custom hook for fetching all users.
 *
 * @param {Partial<Omit<UseApiQueryWithAlertParams<UserSchema[], Error, UserSchema[], ["users"]>, "url" | "queryKey">>} [customOptions]
 * Override default options or add new ones.
 *
 * @returns {UseQueryResult<UserSchema[], Error>} A query result object for fetching users.
 *
 * @example
 * const { data: users, isLoading, error } = useUsersQuery({
 *   options: {
 *     staleTime: 5 * 60 * 1000 // 5 minutes
 *   },
 *   alertOptions: {
 *     successMessage: "Users fetched successfully",
 *     onSuccess: (data) => {
 *       console.log('Fetched users:', data);
 *     }
 *   }
 * });
 */
export function useUsersQuery(
    customOptions?: Partial<
        Omit<
            UseApiQueryWithAlertParams<
                UserSchema[],
                Error,
                UserSchema[],
                ["users"]
            >,
            "url" | "queryKey"
        >
    >,
) {
    return useApiQueryWithAlert<
        UserSchema[],
        Error,
        UserSchema[],
        ["users"]
    >({
        url: "/auth/users/",
        queryKey: ["users"],
        options: customOptions?.options,
        alertOptions: {
            errorMessage: "Failed to fetch users",
            ...customOptions?.alertOptions,
        },
    });
}