import React, { useEffect } from "react";
import classes from "../AccountModal.module.css";
import {
    Box,
    Card,
    Space,
} from "@mantine/core";
import { AccessManagementUserBase } from "types/auth";
import Toggle from "components/_Library/Inputs/Toggle/Toggle";
import { getCookie, parseCookie, setCookie } from "utils/Cookies";
import {
    ADVERTISING_COOKIE_DESCRIPTION,
    ANALYTICS_COOKIE_DESCRIPTION,
    COOKIE_SETTINGS_COOKIE_NAME,
    CookieSettings,
    FUNCTIONALITY_COOKIE_DESCRIPTION,
    HUBSPOT_COOKIE_SETTINGS_COOKIE_NAME,
    NECESSARY_COOKIE_DESCRIPTION,
} from "components/App/Permissions/CookieBanner/CookieBanner";
import { useSetState } from "@mantine/hooks";

interface OwnProps {
    user: AccessManagementUserBase | undefined;
}

type PrivacyTabProps = OwnProps;

enum NotificationOption {
    marketing_emails,
    event_notifications,
}

export type NotificationOptions = keyof typeof NotificationOption;
export type NotificationSettings = { [key in NotificationOptions]: boolean };

const PrivacyTab: React.FC<PrivacyTabProps> = ({ user }) => {
    const [cookieSettings, setCookieSettings] = useSetState<CookieSettings>({
        Necessary: true,
        Analytics: false,
        Functionality: false,
        Advertising: false,
    });
    useEffect(() => {
        let cookieSettingsStr = getCookie(COOKIE_SETTINGS_COOKIE_NAME);
        if (cookieSettingsStr) {
            let settings = parseCookie(cookieSettingsStr);
            updateCookieSettings(settings);
        }
        //(This is being used as componentDidMount, doesn't need dependencies.)
        // eslint-disable-next-line
    }, []);

    const updateCookieSettings = (update: Partial<CookieSettings>) => {
        setCookie(
            COOKIE_SETTINGS_COOKIE_NAME,
            `Analytics:${
                update.Analytics ?? cookieSettings.Analytics
            },Advertising:${
                update.Advertising ?? cookieSettings.Advertising
            },Functionality:${
                update.Functionality ?? cookieSettings.Functionality
            }`,
            182,
        );
        setCookie(
            HUBSPOT_COOKIE_SETTINGS_COOKIE_NAME,
            `Analytics:${
                update.Analytics ?? cookieSettings.Analytics
            },Advertising:${
                update.Advertising ?? cookieSettings.Advertising
            },Functionality:${
                update.Functionality ?? cookieSettings.Functionality
            }`,
            182,
        );
        setCookieSettings({
            Necessary: true,
            Functionality: update.Functionality ?? cookieSettings.Functionality,
            Advertising: update.Advertising ?? cookieSettings.Advertising,
            Analytics: update.Analytics ?? cookieSettings.Analytics,
        });
    };

    return (
        <Box
            className={classes.ContentContainer}
            classNames={{
                item: classes.accordionItem,
                content: classes.accordionContent,
                label: classes.accordionLabel,
                chevron: classes.accordionChevron,
            }}
        >
            <Box>
                <Box
                    className={classes.ContainerHeader}
                >
                    Cookies
                </Box>
                <Box>
                    <div className={classes.SpecificCookie}>
                        <p className={classes.Subheader}>Essential Cookies</p>
                        <Card bg="var(--primary-color)" mt="xs" px="sm" className={classes.TextContent}>
                            {NECESSARY_COOKIE_DESCRIPTION}
                        </Card>
                    </div>
                    <div className={classes.SpecificCookie}>
                        <div className={classes.CookieHeader}>
                            <p className={classes.Subheader}>
                                Analytics Cookies
                            </p>
                            <Toggle
                                onClick={() => {
                                    updateCookieSettings({
                                        Analytics: !cookieSettings.Analytics,
                                    });
                                }}
                                active={cookieSettings.Analytics}
                            />
                        </div>
                        <Card bg="var(--primary-color)" mt="xs" px="sm" className={classes.TextContent}>
                            {ANALYTICS_COOKIE_DESCRIPTION}
                        </Card>
                    </div>
                    <div className={classes.SpecificCookie}>
                        <div className={classes.CookieHeader}>
                            <p className={classes.Subheader}>
                                Functionality Cookies
                            </p>
                            <Toggle
                                onClick={() => {
                                    updateCookieSettings({
                                        Functionality:
                                            !cookieSettings.Functionality,
                                    });
                                }}
                                active={cookieSettings.Functionality}
                            />
                        </div>
                        <Card bg="var(--primary-color)" mt="xs" px="sm" className={classes.TextContent}>
                            {FUNCTIONALITY_COOKIE_DESCRIPTION}
                        </Card>
                    </div>
                    <div className={classes.SpecificCookie}>
                        <div className={classes.CookieHeader}>
                            <p className={classes.Subheader}>
                                Advertising Cookies
                            </p>
                            <Toggle
                                onClick={() => {
                                    updateCookieSettings({
                                        Advertising:
                                            !cookieSettings.Advertising,
                                    });
                                }}
                                active={cookieSettings.Advertising}
                            />
                        </div>
                        <Card bg="var(--primary-color)" mt="xs" px="sm" className={classes.TextContent}>
                            {ADVERTISING_COOKIE_DESCRIPTION}
                        </Card>
                    </div>
                </Box>
            </Box>
            <Space h="md" />
        </Box>
    );
};

export default PrivacyTab;
