import classes from "./ReportText.module.css";
import reportClasses from "../../Report.module.css";
import Markdown from "markdown-to-jsx";
import ReactTooltip from "react-tooltip";
import React, { FC } from "react";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import CopyAlertButton from "components/_Library/CopyAlertButton/CopyAlertButton";
import { Flex, Loader, Tabs } from "@mantine/core";
import { useApiQuery } from "hooks/useAPI";
import { ReportTextSchema } from "crud/reportTextCRUD";

type ReportTextsProps = {
    reportId: string;
};

const ReportTexts: FC<ReportTextsProps> = ({ reportId }) => {
    const { trackUserEventWithCurrentEvent } = useAnalytics();

    const { data: reportTexts, isLoading } = useApiQuery<ReportTextSchema[]>(
        `/events/reports/${reportId}/texts`,
        ["reports", reportId, "texts"],
        {
            staleTime: Infinity,
        },
    );

    if (isLoading) {
        return (
            <Flex justify={"center"}>
                <Loader />
            </Flex>
        );
    }

    return (
        <>
            {reportTexts ? (
                <Tabs
                    defaultValue={reportTexts[0].title}
                    onTabChange={(e) => {
                        if (e) {
                            trackUserEventWithCurrentEvent({
                                name: "report_tab_subtab_clicked",
                                payload: {
                                    tab: e,
                                },
                            });
                        }
                    }}
                >
                    <Tabs.List>
                        {reportTexts
                            .sort(
                                (textItemA, textItemB) =>
                                    textItemA.order - textItemB.order,
                            )
                            .map((textItem) => (
                                <Tabs.Tab
                                    value={textItem.title}
                                    key={textItem.title}
                                >
                                    {textItem.title[0].toUpperCase() +
                                        textItem.title.slice(1)}
                                </Tabs.Tab>
                            ))}
                    </Tabs.List>
                    {reportTexts.map((textItem) => (
                        <Tabs.Panel
                            value={textItem.title}
                            key={textItem.title}
                            w={"100%"}
                            h={"100%"}
                            pt="sm"
                        >
                            <div>
                                <Markdown>{textItem.content}</Markdown>
                                <Flex
                                    w="100%"
                                    justify="right"
                                    className={classes.relative}
                                >
                                    <CopyAlertButton
                                        value={textItem!.content}
                                        alertMessage="Copied to clipboard"
                                        innerText="Copy to clipboard"
                                        buttonClassName={
                                            reportClasses.CopyButton
                                        }
                                        onCopy={() => {
                                            trackUserEventWithCurrentEvent({
                                                name: "report_copy_selected",
                                                payload: {
                                                    tab: textItem!.title,
                                                },
                                            });
                                        }}
                                    />
                                </Flex>
                            </div>
                        </Tabs.Panel>
                    ))}
                </Tabs>
            ) : (
                <div className={classes.NoTextReports}>
                    No text reports to display
                </div>
            )}

            <ReactTooltip id={"TextTooltip"} place={"top"} effect={"float"} />
        </>
    );
};

export default ReportTexts;
